import * as React from 'react';

import '../../css/Service.css';

export default class Sevices extends React.PureComponent {
    render() {
        return (
			<main id="main-client" className="w-75">
				<div id="content">
					<section id="content1" className="mt-5">
						<h2 className="pb-4 text-center">Nuestros Servicios</h2>
						<p className="text-justify mb-5"><div className="d-flex flex-row"><img src="/images/imgInter1.png" className="invert icon-service" /><h3>Desarrollo en tecnologias .Net y PHP</h3></div>Enfocarnos en pocas plataforma nos vuelve profesionales y nos permite ofrecer un servicio de calidad. Aplicando nuestros años de experiencia en la programación en .NET y PHP hacemos que nuestro portafolio hable por nosotros. Muchas veces hemos manejado muchos proyectos para un mismo cliente manteniendo nuestra relación y servicios con ellos por años como prueba de la seriedad en nuestro trabajo y la confianza de nuestros clientes hacia nosotros.</p>
						<p className="text-justify mb-5"><div className="d-flex flex-row"><img src="/images/imgInter2.png" className="invert icon-service" /><h3>Asesoria técnica en IT</h3></div>Nuestro compromiso con el cliente no solo está basado en la programación, nuestra decada de experiencia como empresa nos permite asesorarlo en temas como adquisicion de hardware necesario para sus necesidades concretas, escalabilidad segun sus futuras necesidades programadas, evaluacion de activos tecnologicos ya existentes y asesoría en sus mejoras. Nuestro convenio con las mayores importadoras de tecnologias en el paíus nos permite ofrecerle una sesoría en precios muy por debajo de otras tiendas tecnologicas.</p>
						<p className="text-justify"><div className="d-flex flex-row"><img src="/images/imgInter3.png" className="invert icon-service" /><h3>Asesoria en Dominio y Hosting</h3></div>Con un mercado con multiples opciones y ofertas, saber contratar el hosting y el dominio puede ser el paso más importante para el exito de su servicio o producto, no es un tema que deba ser pensado y dejado al último, requiere de todo un planeamiento porque en muchos casos su imagen corporativa dependerá de ello. Un hosting debe cumplir con todas sus necesitades y ofrecerle la tranquilidad que su site funcionara las 24/7. No debemos olvidar que la Data es un activo que debe ser muy bien resguardado ya sea dentro de su oficina como en la internet. Tenemos años de experiencia administrando servidores alrededor del mundo y supervisando su correcto funcionamiento. y nosotros estamos para asesorarlo y buscar la mejor solución a su necesidades.</p>
					</section>
				</div>
			</main>
        );
    }
}