import * as React from 'react';

import '../../css/About.css';

export default class About extends React.PureComponent {
    render() {
		return (
			<main id="main-about" className="w-75">				
				<input id="radio1" type="radio" name="css-tabs" defaultChecked />
				<input id="radio2" type="radio" name="css-tabs" />
				<input id="radio3" type="radio" name="css-tabs" />
				<input id="radio4" type="radio" name="css-tabs" />
				<div id="tabs">
					<label id="tab1" htmlFor="radio1">Historia</label>
					<label id="tab2" htmlFor="radio2">Vision</label>
					<label id="tab3" htmlFor="radio3">Mision</label>
					<label id="tab4" htmlFor="radio4">Nosotros</label>
				</div>
				<div id="content">
					<section id="content1" className="mt-5">
						<h3 className="pb-4">El Inicio de una Aventura...</h3>
						<p className="text-justify">Fue durante el año 2006 que la idea de crear una empresa se empezo a crear en la mente de cuatro amigos. Esta idea surge de la propia experiencia de ver como muchas empresas no tenian sus procesos bien establecidos y de como el trato con el cliente siempre se complicaba y muy pocas veces se lograba un producto que satisfaga las espectativas y necesidas planteadas al inicio del proyecto. Es asi y con la premisa de transparencia que estos cuatro amigos inician las conversaciones para establecer las bases de lo que seria esta nueva aventura.</p>
						<p className="text-justify">Elegir un nombre que representara sus convicciones fue el segundo paso. Tenia que tener una connotacion de solidez, dedicacion, entereza, grandeza, permanencia y al mismo tiempo sea actual y relacionado con las nececidades de nuestros clientes. Es asi que se decide por el Golem, una criatura mistica creada para satisfacer las necesidades de quien lo invoca de la mejor forma posible. Pero tambien tenia que ser Urbano, Moderno, Contemporaneo, de la ciudad. Es asi como finalmente nace el Golem Urbano. Nace "URBANGOLEM".</p>
						<p className="text-justify">Finalmente, UrbanGolem inicia con cinco socios, pero luego regresa a los cuatro socios originales. Ya con mas de { new Date().getFullYear() - 2006 } años de experiencia en el mercado, UrbanGolem ha llevado proyectos de diversa naturaleza y tamaño. Esta siempre dispuesto a nuevos retos.</p>
					</section>
					<section id="content2" className="mt-5 w-100">
						<h3 className="pb-4">Lo que queremos lograr...</h3>
						<div className="d-flex flex-row justify-content-center"><p className="blockquote_left">&#x201c;</p><blockquote className="text-center font-italic font-weight-bold pb-3 mt-3">Ser reconocidos por los productos que desarrollamos al igual que por la calidad de atencion y compromiso con nuestros clientes</blockquote><p className="blockquote_right">&#x201d;</p></div>
						<p className="text-justify">Productos de calidad que satisfagan las necesidades explicitas e implicitas de nuestros clientes y llegar a tener clientes satisfechos con el producto que reciven al igual que con el proceso que se ha seguido hasta lograr este producto.</p>
						<p className="text-justify">Que nuestros clientes sepan que UrbanGolem esta siempre dispuesto a dar un paso extra, sudar una gota mas, dar ese empuje extra junto a ellos para lograr un producto de excelencia. El compromiso de UrbanGolem es integral, ademas de ejecutores somos asesores, es importante para nosotros que nuestro cliente sienta que nos importan sus necesidades, que nos convertiremos en parte de su cultura empresarial, que su exito es nuestro exito.</p>
						
					</section>
					<section id="content3" className="mt-5">
						<h3 className="pb-4">Como vamos a lograrlo...</h3>
						<div className="d-flex flex-row justify-content-center"><p className="blockquote_left">&#x201c;</p><blockquote className="text-center font-italic font-weight-bold pb-3 mt-3">Cada desarrollo tiene una herramienta adecuada y que mejor se ajusta a este. Es por eso que cada solucion que buscamos para nuestros clientes garantiza que sera la mas apropiada. Desde una implementacion de algun packete existente hasta un desarrollo totalmente personalizado.</blockquote><p className="blockquote_right">&#x201d;</p></div>
						<p className="text-justify">UrbanGolem siempre esta investigando nuevas tecnologias, nuevas formas de alcanzar los mismos resultados de formas mas eficientes. Siempre trabaja en encontrar la mejor forma de transmitir a sus clientes que es lo mas se les ajusta a sus requerimientos. Igualmente esta en constante evolucion para poder captar de manera precisa las necesidades de sus clientes y poder lograr esa empatia que estable un lazo fuerte para trabajar juntos.</p>
						<p className="text-justify">Al igual que una maquina bien aceitada y optimizada, UrbanGolem busca que cada producto creado se desempeñe de forma optima y al tope de sus capacidades, para lo cual consideramos el control de calidad como una etapa fundamental del desarrollo, es durante esta etapa que se pone a prueba el sistema y se le exige al maximo, realizando todos los ajustes necesarios para poder lograr ese punto de performance que asombrara a nuestros clientes y creara un valor agregado adicional al simple desarrollo desatendido.</p>
					</section>
					<section id="content4" className="mt-5 w-100">
						<h3 className="pb-4">Quien esta detras del Golem...</h3>
						<div className="d-flex flex-row w-100 justify-content-between mt-5">
							<div className="text-center ml-5">
								<img className="rounded-circle" src="/images/alonso.jpg" />
								<h3>Manuel A. de Pierola</h3>
								<h4>Gerente General</h4>
							</div>
							<div className="text-center">
								<img className="rounded-circle" width="200px" height="200px" src="/images/dax.jpg" />
								<h3>Danny R. Siancas</h3>
								<h4>Gerente de Desarrollo</h4>
							</div>
							<div className="text-center mr-5">
								<img className="rounded-circle" src="/images/jorge.jpg" />
								<h3>Jorge E. Escribens</h3>
								<h4>Gerente de Operaciones</h4>
							</div>
						</div>
					</section>
				</div>
			</main>
        );
    }
}