import * as React from 'react';
import { Container } from 'reactstrap';


export default class Footer extends React.PureComponent {
    render() {
        return (
            <Container className="d-flex justify-content-center form-control-sm mt-auto">Desarrollado por Urbangolem&reg;. Todos los derechos reservados &copy;. 2006 - {new Date().getFullYear()}</Container>
        );
    }
}