const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const REFRESH = 'REFRESH';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    login: () => ({
        type: LOGIN,
        lastTick: 0,
        user: {
            Id: 0,
            FirstName: '',
            LastName: '',
            Tipo: 0,
            HaveExportTest: false,
            HaveServiceTest: false,
            SubNavigationOptions: []
        }
    }),
    logout: () => ({
        type: LOGOUT,
        lastTick: 0,
        user: {
            Id: 0,
            FirstName: '',
            LastName: '',
            Tipo: 0,
            HaveExportTest: false,
            HaveServiceTest: false,
            SubNavigationOptions: []
        }
    }),
    refesh: () => ({
        type: REFRESH,
        lastTick: 0
    })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = (state = { auth: false, lastTick: 0, user: { Id: 0, FirstName: '', LastName: '', Tipo: 0, HaveExportTest: false, HaveServiceTest: false, SubNavigationOptions: [] } }, incomingAction) => {
    const action = incomingAction;
    switch (action.type) {
        case LOGIN:
            return {
                auth: true,
                lastTick: (new Date()).getTime(),
                user: {
                    Id: action.user.Id,
                    FirstName: action.user.FirstName,
                    LastName: action.user.LastName,
                    Tipo: action.user.Tipo,
                    HaveExportTest: action.user.HaveExportTest,
                    HaveServiceTest: action.user.HaveServiceTest,
                    SubNavigationOptions: action.user.SubNavigationOptions
                }                
            };
        case LOGOUT:
            return {
                auth: false,
                lastTick: 0,
                user: {
                    Id: 0,
                    FirstName: '',
                    LastName: '',
                    Tipo: 0,
                    HaveExportTest: false,
                    HaveServiceTest: false,
                    SubNavigationOptions: []                    
                }
            };
        case REFRESH:
            return {
                auth: true,
                lastTick: (new Date()).getTime(),
                user: {
                    Id: action.user.Id,
                    FirstName: action.user.FirstName,
                    LastName: action.user.LastName,
                    Tipo: action.user.Tipo,
                    HaveExportTest: action.user.HaveExportTest,
                    HaveServiceTest: action.user.HaveServiceTest,
                    SubNavigationOptions: action.user.SubNavigationOptions
                }
            }
        default:
            return state;
    }
};
