import * as React from 'react';
import NavMenu from '../common/NavBarAuth';

export default (props) => (
    <React.Fragment>
        <NavMenu user={props.user} />
        <div>
            {props.children}
            <br />
            <br />
            <br />
            <br />
        </div>
    </React.Fragment>
);