const LANG = 'LANG';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    changelang: () => ({
        type: LANG,
        lang: 'es'
    })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = (state = { lang: 'es' }, incomingAction) => {
    const action = incomingAction;
    switch (action.type) {
        case LANG:
            return {
                lang: action.lang,
            };
        default:
            return state;
    }
};
