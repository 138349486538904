import * as React from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavLink, UncontrolledDropdown, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { store } from '../../index';
import Config from '../../config';

//CSS and images import
import '../../css/NavBar.css';
import logoug from '../../images/logoug.png';


export default class NavMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.doLogout = this.doLogout.bind(this);
        this.configObj = new Config();
    }

    state = {
        isOpen: false
    };


    doLogout() {
        document.body.classList.remove('page');
        store.dispatch({
            type: 'LOGOUT'
        });
        window.location = this.configObj.rutexurl + "/seguridad/autenticacion/logout";
    }

    getSubNavItem(OpcId) {
        let returnItem = '';
        switch (OpcId) {
            case 2:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/roles">Roles</NavLink>
                break;
            case 3:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/usuarios">Usuario</NavLink>
                break;
            case 4:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/tipos">Tipos</NavLink>
                break;
            case 5:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/paginas">Páginas</NavLink>
                break;
            case 6:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/control-paginas">Control Páginas</NavLink>
                break;
            case 7:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/">Inicio</NavLink>
                break;
            case 8:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/servicios">Servicios</NavLink>
                break;
            case 9:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/eventos">Eventos</NavLink>
                break;
            case 10:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/evaluaciones">Evaluaciones</NavLink>
                break;
            case 11:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/reportes">Reportes</NavLink>
                break;
            case 12:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/notificaciones">Notificaciones</NavLink>
                break;
            case 13:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/emprendedor-servicios">Emprendedor Servicios</NavLink>
                break;
            case 15:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/proveedor-servicios">Proveedor Servicios</NavLink>
                break;
            case 26:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/resultado-encuensta">Resultado Encuesta</NavLink>
                break;
            case 63:
                returnItem = <NavLink key={"SubNavItem" + OpcId} tag={Link} className="subMenu-Link" to="/evaluar-test-servicios">Evaluar Test Servicios</NavLink>
                break;
            default:
                returnItem = '';
        }
        return returnItem;
    }

    getMenuByType(user) {
        switch (user.Tipo) {
            case 889:
                return (
                    <DropdownMenu right>
                        <DropdownItem>
                            <a className="text-dark nav-link" href={this.configObj.rutexurl + "/panel-control#emprendedorperfil"}>Mi Perfil</a>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                            <a className="text-dark nav-link" href={this.configObj.rutexurl + "/panel-control#usuariocambiarcontrasenia"}>Cambiar Contraseña</a>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/" onClick={this.doLogout}>Logout</NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                );
            case 890:
                let empMenu = [
                        <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/">Mi Perfil</NavLink>,
                        </DropdownItem>,
                        <DropdownItem divider />
                ];
                if(user.HaveServiceTest){
                            empMenu = [
                                ...empMenu,
                                <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/">Mi Test del Exportador</NavLink>,
                        </DropdownItem>
                            ]}
                if(user.HaveServiceTest){
                            empMenu = [
                                ...empMenu,
                                <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/">Mi Test de Servicios</NavLink>,
                        </DropdownItem>
                            ]}
                empMenu = [
                        ...empMenu,
                        <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/">Empresa Servicios</NavLink>,
                        </DropdownItem>,
                        <DropdownItem divider />,
                        <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/">Cambiar Contraseña</NavLink>,
                        </DropdownItem>,
                        <DropdownItem>,
                            <NavLink tag={Link} className="text-dark" to="/" onClick={this.doLogout}>Logout</NavLink>,
                        </DropdownItem>                    
                ];
                return <DropdownMenu right>{empMenu}</DropdownMenu>;
            default:
                return '';
        }
    }

    constructSubNav(user) {
        let returnOptions = [];
        for (let i in user.SubNavigationOptions) {
            returnOptions.push(this.getSubNavItem(user.SubNavigationOptions[i]));
        }
        return returnOptions;
    }

    generateSubMenu(user) {

        return (
            <Container className="container-fluid mt-5">
                <section>
                    <Container className="row">
                        <div className="col-sm-12">
                            <Nav className="navbar navbar-default subnav float-right" navbar>
                                <NavItem>
                                    {this.constructSubNav(user)}
                                </NavItem>
                            </Nav>
                        </div>
                    </Container>
                </section>
            </Container>
        );
    }

    render() {
        return (
            <section>
                <header className="header-pp-navigation">
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow mb-3" light>
                        <Container>
                            <NavbarBrand className="navbar-brand navbrand-pp" tag={Link} to={this.props.user.Tipo === 889 ? "/emprendedor-servicios" : "/dashboard"} >
                                <img src={logoug} className="navbrand-img" alt="logotipo de la marca Perú" />
                            </NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className="mr-2" />
                            <span className="text-white font-weight-bold">Ruta Exportadora</span>
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                                <Nav className="navbar-nav flex-grow" navbar>
                                    <li className="divider-vertical"></li>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="text-white">
                                            {this.props.user.FirstName} {this.props.user.LastName}
                                        </DropdownToggle>
                                        {this.getMenuByType(this.props.user)}
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink className="text-white" tag={Link} to="/calendario-eventos" ><i className="fa fa-calendar" aria-hidden="true"></i></NavLink>
                                </NavItem>
                            </ul>
                        </Container>
                    </Navbar>
                </header>
                {this.generateSubMenu(this.props.user)}
            </section>
        );
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
