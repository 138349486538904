import * as React from 'react';
import { Collapse, Nav, Navbar, NavItem, NavLink, NavbarBrand, NavbarToggler, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import AjaxLib from '../../libs/ajax';
import { arrayHasOwnIndex } from '../../Utils';
import { store } from '../../index';

//Static Files
import '../../css/NavBar.css';
import logoug from '../../images/logoug.png';
import ugtext from '../../images/UrbanGolemText.png';

export default class NavMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.modalToggle = this.modalToggle.bind(this);
    }

    getMenuByLang() {
        let fMenu = [];
        let menu = [];
        switch (this.props.lang) {
            case 'en':
                menu = [
                    { link: "/aboutus/", text: "About Us" },
                    { link: "/clients/", text: "Clients" },
                    { link: "/services/", text: "Services" },
                    { link: "/contactus/", text: "Contact Us" },
                ];
                break;
            case 'es':
            default:
                menu = [
                    { link: "/nosotros/", text: "Nosotros" },
                    { link: "/clientes/", text: "Clientes" },
                    { link: "/servicios/", text: "Servicios" },
                    { link: "/contactenos/", text: "Contactenos" },
                ];
                break;
        }
        for (let i in menu) {
            if (arrayHasOwnIndex(menu, i)) {
                fMenu.push(<NavItem key={"menuitem-" + i}><NavLink tag={Link} to={menu[i].link}>{menu[i].text}</NavLink></NavItem>)
            }
        }
        return fMenu;
    }

    modalToggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    render() {        
        return (
            <Navbar expand="md" className="border-bottom p-md-0 justify-content-start" light>
                <NavbarBrand tag={Link} to="/" className="text-md-center p-md-0"><img className="w-50" src={logoug} alt="logotipo de ug" /></NavbarBrand>
                <div>
                    <img className="w-75" style={{ marginLeft: "-60px" }} src={ugtext} alt="logotipo de ug" />
                </div>
                <NavbarToggler onClick={this.modalToggle} className="mr-2" />
                <Collapse isOpen={this.state.isOpen} className="ml-auto" navbar>
                    <Nav className="justify-content-around ml-auto text-uppercase font-15em ff-monospace" navbar>
                        {this.getMenuByLang()}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}