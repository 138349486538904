import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Container } from 'reactstrap';
import { arrayHasOwnIndex } from '../../Utils';

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Home extends React.PureComponent {


    carouselItems() {
        let lJSXCarouselItems = [];
        let lCarouselItems = [
            { src: '/images/carousel1.jpg', text: "Como toda historia esta comienza hace mucho tiempo" },
            { src: '/images/carousel2.jpg', text: "En una ciudad como hay muchas otras dispersas en el mundo agitado" },
            { src: '/images/carousel3.jpg', text: "Donde la gente sigue su ritmo de vida tranquilo" },
            { src: '/images/carousel5.jpg', text: "Pero algunos siempre pensando \"Cual es mi siguiente reto?\"" },
            { src: '/images/carousel7.jpg', text: "Encontrando en la tecnologia una nueva pasión" },
            { src: '/images/carousel8.jpg', text: "Y en la programación una nueva herramienta para crear un nuevo arte" },
            { src: '/images/carousel9.jpg', text: "Pensando fuera de la caja, pero hay que darse cuenta de la realidad. No hay ninguna caja!" },
            { src: '/images/carousel10.jpg', text: "Creando un nuevo ambiente de trabajo" },
            { src: '/images/carousel11.jpg', text: "Trabajando desde todos lados y con diferentes talentos" },
            { src: '/images/carousel12.jpg', text: "La distancia ya no es mas un impedimento, es ahora una oportunidad" },
            { src: '/images/carousel13.jpg', text: "Teniendo toda la informacion disponible para lograr cada objetivo" },
            { src: '/images/carousel14.jpg', text: "Creando algo increible para cada necesidad y enfocados en la calidad" },
            { src: '/images/carousel15.jpg', text: "Manteniendose siempre a la vanguardia de lo existente" },
            { src: '/images/carousel16.jpg', text: "Para UrbanGolem la perfeccion es la meta. Unete a la experiencia UG!" }
        ];
        let lCarouselTotalItems = lCarouselItems.length;
        for (let i = 0; i < lCarouselTotalItems; i++) {
            if (arrayHasOwnIndex(lCarouselItems, i)){
                lJSXCarouselItems.push(<div key={"carouseldiv-" + i}>
                    <img src={lCarouselItems[i].src} style={{ height: "700px", verticalAlign: "middle" }} />
                    <p className="legend" style={{ opacity: "0.60" }}>
                        {lCarouselItems[i].text}
                    </p>
                </div>);
            }
        }
        return lJSXCarouselItems;
    }

    render() {
        return (
            <div className="d-flex flex-row" style={{ backgroundColor: "black" }}>
                <img className="onBig" src="/images/bgInterna.jpg" />
                <Container>
                    <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
                        {this.carouselItems()}
                    </Carousel>
                </Container>
                <img className="onBig" src="/images/bgInterna.jpg" />
            </div>
            );
    }
}