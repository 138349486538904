export default class Config {

    apigateway = process.env.REACT_APP_API_GW_URL
    apigwport = process.env.REACT_APP_API_GW_DEFAULT_PORT
    apiUrls = {
        login: {
            path: '/login',
            method: 'POST'
        },
    }
}