import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducers } from '.';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export default function configureStore(history, initialState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const persistConfig = {
        key: 'root',
        storage: storage,
        blacklist: ['router'],
        stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
    };

    const pReducer = persistReducer(persistConfig, rootReducer);

    return createStore(
        pReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
