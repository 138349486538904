export function playVideo() {
    var htmlVideo = "<div class='video-responsive'><iframe width='500' id='video-player' height='315' src='https://www.youtube.com/embed/krVkvyQjyBQ?&autoplay=1&rel=0&showinfo=0&border=0' frameborder='0' allowfullscreen></iframe></div>";
    var videoBanner = document.querySelector("#video-banner");
    videoBanner.innerHTML = htmlVideo;
}

export function dataifyForm(formName) {
    let formElement = document.querySelector(formName)
    return new URLSearchParams(new FormData(formElement)).toString();
}

export function arrayHasOwnIndex(array, prop) {
    return array.hasOwnProperty(prop) && /^0$|^[1-9]\d*$/.test(prop) && prop <= 4294967294; // 2^32 - 2
}

export function downloadfile(url) {
    if (url !== null && url !== undefined) {
        let container = document.getElementById('iframedownloadfile');
        if (container === null) {            
            container = document.createElement('iframe');
            container.id = 'iframedownloadfile';
            container.style.display = 'none';
            document.body.appendChild(container);
        }
        container.src = url;
        return true;
    }
    return false;
}