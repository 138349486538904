import * as React from 'react';

export default class Contact extends React.PureComponent {
    render() {
        return (
			<main id="main-client" className="w-75">
				<div id="content">
					<section id="content1" className="mt-5 w-100">
						<h3 className="text-center">Contactenos</h3>
						<p className="mb-4">Estamos siempre listos a atender sus comentarios, dudas o solicitudes. Puede usar cualquiera de los siguientes medios para ponerse en contacto con nosotros.</p>
						<div className="d-flex flex-column flex-md-row">
							<div className="d-flex flex-column w-50 p-2 pr-2">
								<h4 className="pb-4 text-center">Escribenos</h4>
								<div>
									<div className="form-group text-left">
										<label htmlFor="contact_name">Nombre</label>
										<input type="text" id="contact_name" name="contact_name" placeholder="Ingrese su nombre..." className="form-control border-dark border-top-0 border-right-0" required />
									</div>
									<div className="form-group text-left">
										<label htmlFor="contact_email">Correo</label>
										<input type="text" id="contact_email" name="contact_email" placeholder="Ingrese su correo electronico..." className="form-control rounded border-dark  border-top-0 border-right-0" required />
									</div>
									<div className="form-group text-left">
										<label htmlFor="contact_message">Mensaje</label>
										<textarea id="contact_message" rows="5" name="contact_message" placeholder="Ingrese su mensaje..." className="form-control border-dark border-top-0 border-right-0" required></textarea>
									</div>
									<div className="text-center">
										<button id="contact_button" name="contact_button" className="bg-dark border-dark rounded text-light w-25">Enviar</button>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-between p-2 w-50">
								<div className="badge-light border h-30 p-4 rounded shadow-hover">
									<p><b>Email:</b><br /><a href="mailto:info@urbangolem.com" className="text-dark text-decoration-none">info@urbangolem.com</a></p>
								</div>
								<div className="align-middle badge-light border d-inline h-30 p-4 rounded shadow-hover">
										<p><b>Telefono:</b><br /><a href="tel:+5116553607" className="text-dark text-decoration-none">+511-6553607</a></p>
								</div>
								<div className="align-middle badge-light border d-inline h-30 p-4 rounded shadow-hover">
									<p className="font-weight-bold">Horario de Atencion</p>
									<span>Lunes a Viernes de 9:00 a. m. a 6:00 p. m.</span>
									<span>Sabados de 10:00 a. m. a 1 p. m.</span>
								</div>
							</div>
						</div>
					</section>
				</div>
			</main>

        );
    }
}