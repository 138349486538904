import React from 'react';
import * as LanguageStore from './store/Language';
import { connect } from 'react-redux';
import Config from './config';
import Auth from './Auth';

class Language extends React.PureComponent {

    render() {
        return (
            <Auth history={this.props.history} lang={this.props.lang} />
        );
    }
}


/*
 * Maping properties and actions
 */
export default connect(
    (state) => state.lang,
    LanguageStore.actionCreators
)(Language);

