import * as React from 'react';
import { Route, Redirect } from 'react-router';
import Home from './components/Home/Home';
import About from './components/Areas/About';
import Services from './components/Areas/Services';
import Clients from './components/Areas/Clients';
import Contact from './components/Areas/Contact';


export class Routes extends React.PureComponent {

    render() {
        switch (this.props.lang) {
            case 'en':
                return [
                    <Route exact key="RouteNAuth1" path='/en'><Home {...this.props} /></Route>,
                    <Route exact key="RouteNAuth2" path='/aboutus'><About {...this.props} /></Route>,
                    <Route exact key="RouteNAuth3" path='/services'><Services {...this.props} /></Route>,
                    <Route exact key="RouteNAuth4" path='/clients'><Clients {...this.props} /></Route>,
                    <Route exact key="RouteNAuth5" path='/contactus'><Contact {...this.props} /></Route>
                ];
            case 'es':
            default:
                return [
                    <Route exact key="RouteNAuth1" path='/'><Home {...this.props} /></Route>,
                    <Route exact key="RouteNAuth2" path='/nosotros'><About {...this.props}/></Route>,
                    <Route exact key="RouteNAuth3" path='/servicios'><Services {...this.props} /></Route>,
                    <Route exact key="RouteNAuth4" path='/clientes'><Clients {...this.props} /></Route>,
                    <Route exact key="RouteNAuth5" path='/contactenos'><Contact {...this.props} /></Route>

                    /*<Route exact key="RouteAuth1" path='/dashboard'>{!this.props.auth ? <Redirect to="/" /> : <Dashboard />}</Route>,
                    <Route exact key="RouteAuth2" path='/emprendedor-servicios' render={function (props) { return !auth ? <Redirect to="/" /> : <EmprendedorServicios user={user} {...props} /> }} />, 
                    <Route exact key="RouteAuth5" path='/autologoutuser' render={(props) => <Logout {...props} />} />,
                    <Route exact key="RouteBoth1" path='/calendario-eventos' render={(props) => <CalendarEvents auth={auth} {...props} />} />,
                    <Route key="RouteAuth3" path='/perfil/:type/:id' render={(props) => !auth ? <Redirect to="/" /> : <PerfilRouted {...props} /> } />,
                    <Route key="RouteAuth4" path='/autologinuser/:id' render={(props) => <Login {...props} />} />*/
                ];
        }
    }
}

