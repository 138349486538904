import * as React from 'react';
import NavMenu from '../common/NavBarNoAuth';
import Footer from '../Layouts/Footer';

export default (props) => (
    <React.Fragment>
        <NavMenu history={props.history} lang={props.lang} />
        <div className="mt-4">
            {props.children}
        </div>
        <Footer />
    </React.Fragment>
);