import React from 'react';
import LayoutNoAuth from './components/Layouts/LayoutNoAuth';
import LayoutAuth from './components/Layouts/LayoutAuth';
import { Routes } from './Routes';
import * as AuthStore from './store/Auth';
import { connect } from 'react-redux';
import { store } from '.';
import Config from './config';

class Auth extends React.PureComponent {

    componentDidMount() {
        this.configObj = new Config();
        /*this.checkLoginStatus();  */
    }

    /*checkLoginStatus() {
        if (this.props.lastTick > 0 && this.props.lastTick + 360000 > (new Date()).getTime()) {
            store.dispatch({
                type: 'REFRESH',
                lastTick: (new Date()).getTime(),
                user: this.props.user
            });
            setTimeout(this.checkLoginStatus.bind(this), 60000);
        } else {            
            if (this.props.auth) {
                store.dispatch({
                    type: 'LOGOUT'
                });
                window.location = this.configObj.rutexurl + "/panel-control#seguridadlogout";
            } else {
                setTimeout(this.checkLoginStatus.bind(this), 60000);
            }
        }
    }*/

    render() {
        if (this.props.auth) {
            document.body.classList.add('page');
            return (
                <LayoutAuth history={this.props.history} user={this.props.user}>
                    <Routes auth={true} user={this.props.user} history={this.props.history} />
                </LayoutAuth>
            );
        } else {
            document.body.classList.remove('page');
            return (
                <LayoutNoAuth history={this.props.history}>
                    <Routes auth={false} history={this.props.history} />
                </LayoutNoAuth>
            )
        }
    }
}


/*
 * Maping properties and actions
 */
export default connect(
    (state) => state.auth,
    AuthStore.actionCreators
)(Auth);

