import * as React from 'react';

import '../../css/Client.css';

export default class Clients extends React.PureComponent {
    render() {
        return (
			<main id="main-client" className="w-75">
				<div id="content">
					<section id="content1" className="mt-5">
						<h2 className="pb-4 text-center">Nuestros Clientes</h2>
						<p className="text-justify">UrbanGolem esta comprometido a lograr siempre los mejores resultados y exceder las expectativas de nuestros clientes. Con un personal de primera para lograr siempre la mejor calidad en nuestros proyectos. Urban Golem cuenta con una selecta cartera de clientes, lo que le permite enfocarse en las necesidades especificas de cada uno para obtener siempre las mejores soluciones para sus requerimientos. Es por estos motivos que cada vez son mas los clientes que forman parte nuestro muro de exitos.</p>
						<p>Conozca a algunos de nuestros clientes</p>
						<div className="d-flex flex-row flex-wrap justify-content-center">
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/futurealba.png" width="180px" height="112px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/pmi.png" width="361px" height="57px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/srburns.png" width="204px" height="94px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/wundermanthompson.png" width="175px" height="79px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/renzocosta.png" width="200px" height="80px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/promperu.png" width="178px" height="74px" /></div>
							<div className="d-table-cell text-center align-middle invert m-5"><img src="/images/clients/t13sp_tp.png" width="178px" height="74px" /></div>
						</div>
					</section>
				</div>
			</main>
        );
    }
}